import axios from '../axios';

export const all = (params = {}) => {
  return axios.get('/downloadable-materials/tags', { params });
};

export const show = (id, params = {}) => {
  return axios.get(`/downloadable-materials/tags/${id}`, { params });
};

export const create = (payload) => {
  return axios.post('/downloadable-materials/tags', payload);
};

export const update = (id, payload) => {
  return axios.put(`/downloadable-materials/tags/${id}`, payload);
};

export const destroy = (id) => {
  return axios.delete(`/downloadable-materials/tags/${id}`);
};
