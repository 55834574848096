export const getLanguages = () => [
  {
    lang: 'pl',
    value: 'Polski',
  },
  {
    lang: 'en',
    value: 'Angielski',
  },
  {
    lang: 'hu',
    value: 'Węgierski',
  },
];

export const getLanguageByLang = (lang) => getLanguages().find((l) => l.lang === lang);

export const DEFAULT_LANGUAGE = 'pl';
