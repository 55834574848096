<template>
  <validation-observer ref="tagObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <h4 class="mb-2">Informacje o tagu</h4>

      <b-col>
        <b-form-group label="Język">
          <validation-provider #default="{ errors }" name="Język" rules="required">
            <v-select
              id="v-language-select"
              label="value"
              placeholder="Wybierz język"
              v-model="lang"
              :clearable="false"
              :deselectFromDropdown="false"
              :options="languages"
              :disabled="isCreateView"
              @input="fetchByLocale" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Nazwa">
          <validation-provider #default="{ errors }" name="Nazwa" rules="required|min:3|max:100">
            <b-form-input v-model="form.name" placeholder="Nazwa" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Zapisz </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';
import { getLanguages } from '@/constants/languages';

export default {
  mixins: [NotificationMixin],
  props: {
    tag: {
      type: Object,
      default: () => {
        name: '';
      },
    },
    isCreateView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      lang: {
        lang: 'pl',
        value: 'Polski',
      },
      form: this.tag,
      languages: getLanguages(),
    };
  },

  watch: {
    tag(newValue) {
      this.form = newValue;
    },
  },

  methods: {
    fetchByLocale() {
      this.$emit('fetchByLocale', this.lang.lang);
    },
    onSubmit() {
      const data = {
        ...this.form,
        lang: this.lang.lang,
      };

      this.$emit('onSubmit', data);
    },
    validationForm() {
      this.$refs.tagObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }
        this.showFormValidationNotification();
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
